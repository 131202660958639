@import '../lib/fontawesome/all.min.css';

html{
	scroll-behavior:smooth;
}

.bg-hero {
    background-image: url('/img/docteur-olivier-bouchain.jpg');
}

#menuHamburgerIcon {
    @apply text-6xl lg:text-6xl;
    cursor: pointer;
}

h1 {
    @apply font-display text-4xl md:text-7xl lg:text-8xl;
}

h2 {
    @apply font-display text-4xl pt-8 pb-3;
}

h3 {
    @apply font-display font-bold text-2xl pt-4 pb-3;
}

p, ul {
	@apply py-3 leading-9 text-lg;
}

a {
    @apply font-bold;
}

figcaption {
	@apply pt-4 pb-6 text-base leading-7;
}

address {
	font-style: normal;
}

iframe {
	@apply w-full;
	height: 450px;
}

.btn-primary {
    @apply py-4 px-8 bg-blue-700 text-gray-50 transition-all;
}

.btn-primary:hover {
    @apply bg-blue-800;
}

.btn-secondary {
    @apply py-4 px-8 bg-gray-50 text-blue-700 transition-all;
}

.btn-secondary:hover {
    @apply bg-gray-100 text-blue-800;
}